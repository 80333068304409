body {
  background-color: #181818;
  color: white;
  font-family: Barlow, sans-serif;
}

@font-face {
  font-family: 'Barlow';
  src: url('./fonts/Barlow-Regular.woff') format('woff'),
    url('./fonts/Barlow-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow';
  src: url('./fonts/Barlow-SemiBold.woff') format('woff'),
    url('./fonts/Barlow-SemiBold.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
