.names {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 1.5;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    &__title {
        font-size: 28px;
        font-weight: 400;
    }

    &__name {
        font-size: 48px;
        font-weight: 700;
    }

    .names__image {
        img {
            max-width: 450px;
            border-radius: 2px;
            display: block;
            box-shadow: inset 0 0 100px 20px #ED1A24;
        }

        display: flex;
        justify-content: center;
        margin: 15px;




    }

}